/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import favicon from '../images/icon/favicon.ico'
import favicon16 from '../images/icon/favicon-16x16.png'
import favicon32 from '../images/icon/favicon-32x32.png'
import favicon96 from '../images/icon/favicon-96x96.png'

import appleIcon57 from '../images/icon/apple-icon-57x57.png'
import appleIcon60 from '../images/icon/apple-icon-60x60.png'
import appleIcon72 from '../images/icon/apple-icon-72x72.png'
import appleIcon76 from '../images/icon/apple-icon-76x76.png'
import appleIcon114 from '../images/icon/apple-icon-114x114.png'
import appleIcon120 from '../images/icon/apple-icon-120x120.png'
import appleIcon144 from '../images/icon/apple-icon-144x144.png'
import appleIcon152 from '../images/icon/apple-icon-152x152.png'

import msIcon70 from '../images/icon/ms-icon-70x70.png'
import msIcon144 from '../images/icon/ms-icon-144x144.png'
import msIcon150 from '../images/icon/ms-icon-150x150.png'
import msIcon310 from '../images/icon/ms-icon-310x310.png'

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      link={[
        { rel: 'icon', type: 'image/png', href: `${favicon}` },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${favicon16}` },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${favicon32}` },
        { rel: 'shortcut icon', type: 'image/png', href: `${favicon96}` },
        { rel: 'msapplication-TileColor', content: '#ffffff' },
        { rel: 'msapplication-square70x70logo', content: `${msIcon70}` },
        { rel: 'msapplication-square144x144logo', content: `${msIcon144}` },
        { rel: 'msapplication-square150x150logo', content: `${msIcon150}` },
        { rel: 'msapplication-square310x310logo', content: `${msIcon310}` },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${favicon32}` },
        { rel: 'shortcut icon', type: 'image/png', href: `${favicon96}` },
        //{ rel: "apple-touch-icon", type: "image/png", href: `${appleIcon}` }, // is 192x192
        { rel: 'apple-icon', type: 'image/png', sizes: '57x57', href: `${appleIcon57}` },
        { rel: 'apple-icon', type: 'image/png', sizes: '60x60', href: `${appleIcon60}` },
        { rel: 'apple-icon', type: 'image/png', sizes: '72x72', href: `${appleIcon72}` },
        { rel: 'apple-icon', type: 'image/png', sizes: '76x76', href: `${appleIcon76}` },
        { rel: 'apple-icon', type: 'image/png', sizes: '114x114', href: `${appleIcon114}` },
        { rel: 'apple-icon', type: 'image/png', sizes: '120x120', href: `${appleIcon120}` },
        { rel: 'apple-icon', type: 'image/png', sizes: '144x144', href: `${appleIcon144}` },
        { rel: 'apple-icon', type: 'image/png', sizes: '152x152', href: `${appleIcon152}` },
        //{ rel: "apple-touch-icon", type: "image/png", sizes: "180x180", href: `${appleIcon180}` },
        /*{rel:"android-icon", type:"image/png", sizes:"36x36", href:`${androidIcon36}`},
        {rel:"android-icon", type:"image/png", sizes:"48x48", href:`${androidIcon48}`},
        {rel:"android-icon", type:"image/png", sizes:"72x72", href:`${androidIcon72}`},
        {rel:"android-icon", type:"image/png", sizes:"96x96", href:`${androidIcon96}`},
        {rel:"android-icon", type:"image/png", sizes:"144x144", href:`${androidIcon144}`},
        {rel:"android-icon", type:"image/png", sizes:"192x192", href:`${androidIcon192}`},
        {rel:"manifest", href:`${manifest}`}*/
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
